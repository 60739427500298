import Cookies from 'js-cookie';
document.addEventListener("DOMContentLoaded", function () {
    var cookieName = 'actionStrip';
    var container = document.getElementById('actionStrip');
    var button = document.getElementById('actionStripClose');
    if (!container)
        return;
    // close handler
    button.addEventListener('click', function () {
        container.style.display = 'none';
        Cookies.set(cookieName, 'disabled', { expires: 365 });
    });
    // pokud neexistuje cookie zobrazi se lista
    if (!Cookies.get(cookieName))
        container.style.display = 'block';
});
