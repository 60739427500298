import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
document.addEventListener("DOMContentLoaded", function () {
    var swiperhp = new Swiper('.swiperhp', {
        modules: [Navigation, Pagination, Scrollbar],
        loop: true,
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-nexthp',
            prevEl: '.swiper-button-prevhp',
        },
        pagination: {
            el: '.swiper-pagination',
        },
        breakpoints: {
            768: {
                slidesPerView: 1.5,
                centeredSlides: true,
            },
            990: {
                slidesPerView: 3,
                centeredSlides: false,
            },
        },
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });
    var swiper = new Swiper('.swiper', {
        modules: [Navigation, Pagination, Scrollbar],
        loop: true,
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
        },
        breakpoints: {
            768: {
                slidesPerView: 1.5,
                centeredSlides: true,
            },
            990: {
                slidesPerView: 3,
                centeredSlides: false,
            },
        },
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });
    var swiperProduct = new Swiper('.swiperProduct', {
        modules: [Navigation, Pagination, Scrollbar],
        loop: true,
        spaceBetween: 30,
        navigation: {
            nextEl: '.swiper-button-nextPr',
            prevEl: '.swiper-button-prevPr',
        },
        pagination: {
            el: '.swiper-pagination',
        },
        breakpoints: {
            150: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 4,
            },
            990: {
                slidesPerView: 6,
            },
        },
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    });
});
